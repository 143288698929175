// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--52d6a";
export var footerBottom = "footer-module--footer-bottom--6c0be";
export var footerColumn = "footer-module--footer-column--00ec4";
export var footerContainer = "footer-module--footer-container--0d473";
export var footerFbPlugin = "footer-module--footer-fb-plugin--51fb0";
export var footerLogo = "footer-module--footer-logo--dd504";
export var footerLogoSocial = "footer-module--footer-logo-social--1b209";
export var footerRowOne = "footer-module--footer-row-one--44d2e";
export var footerSocialIcons = "footer-module--footer-social-icons--ea931";
export var footerTop = "footer-module--footer-top--ad94b";