// extracted by mini-css-extract-plugin
export var activeMenuItem = "header-module--active-menu-item--90617";
export var children = "header-module--children--f7137";
export var desktopMenu = "header-module--desktop-menu--af9a0";
export var fixedMenu = "header-module--fixed-menu--6b824";
export var hamburgerButton = "header-module--hamburger-button--867c6";
export var hamburgerIcon = "header-module--hamburger-icon--e5fda";
export var hamburgerIconBar = "header-module--hamburger-icon-bar--a4686";
export var header = "header-module--header--284be";
export var headerBanner = "header-module--header-banner--4c589";
export var headerBannerWrapper = "header-module--header-banner-wrapper--bfc67";
export var headerMenu = "header-module--header-menu--9f2ec";
export var logo = "header-module--logo--0bfc3";
export var logoWrapper = "header-module--logo-wrapper--ccca4";
export var mobileLogo = "header-module--mobile-logo--2aad9";
export var mobileMenu = "header-module--mobile-menu--0c4b0";
export var parent = "header-module--parent--67715";
export var shrinked = "header-module--shrinked--b126d";
export var special = "header-module--special--04c26";